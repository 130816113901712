<template>
  <div class="clients-order-table-reassign-stylist">
    <base-button class="reassign-button"
                 ref="reassignButton"
                 transparent
                 square
                 @click.stop>
      {{ order.stylist.nickName }}
    </base-button>

    <div class="dropdown"
         ref="reassignButtonDropdown">
      <base-multiselect v-model="selectedStylist"
                        :options="stylists"
                        option-label="nickName"
                        track-by="id"
                        :placeholder="$t('clientsOrders.list.clientsOrdersTable.clientsOrdersTableReassignStylist.searchPlaceholder')"
                        open-direction="bottom"
                        :loading="loading"
                        :internal-search="false"
                        :max-height="600"
                        :show-no-results="true"
                        @search-change="searchStylists">
        <template slot="option"
                  slot-scope="props">
          <div class="stylist">
            <base-avatar :user="props.option"/>
            <div class="nickname">
              {{ props.option.nickName }}
            </div>
          </div>
        </template>

        <template slot="noResult">
          {{ $t('clientsOrders.list.clientsOrdersTable.clientsOrdersTableReassignStylist.noResultsPlaceholder') }}
        </template>
      </base-multiselect>

      <div class="actions">
        <base-button :square="true"
                     size="small"
                     class="submit-button"
                     @click="submit">
          {{ $t('clientsOrders.list.clientsOrdersTable.clientsOrdersTableReassignStylist.submit') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import ordersService from '@/services/queries/ordersQueries'

  export default {
    props: {
      order: {
        required: true,
        type: Object
      },

      fullStylistList: {
        required: true,
        type: Array
      }
    },

    data() {
      return {
        selectedStylist: null,
        stylists: [],
        params: {
          user_type: 'stylist'
        },
        loading: false
      }
    },

    mounted() {
      this.initStylistReassignPopover()
    },

    methods: {
      searchStylists: _.debounce(function(query) {
        this.loading = true

        profileService.get({ ...this.params, search_text: query }).then(response => {
          this.stylists = query ? response.items : this.fullStylistList
          this.loading = false
        })
      }, 500),

      submit() {
        if (!this.selectedStylist) {
          this.close()

          return
        }

        ordersService.changeStylist(this.order.id, this.selectedStylist.id).then(() => {
          this.$emit('reassign')
          this.close()
        })
      },

      close() {
        this.$refs.reassignButton.$el._tippy.hide()
      },

      initStylistReassignPopover() {
        let vm = this

        tippy(this.$refs.reassignButton.$el, {
          content: this.$refs.reassignButtonDropdown,
          interactive: true,
          trigger: 'click',
          theme: 'light dropdown-menu',
          animation: 'shift-away',
          arrow: true,
          placement: 'bottom',
          inertia: true,
          duration: [600, 300],
          onShow() {
            vm.stylists = vm.fullStylistList
            vm.selectedStylist = vm.order.stylist
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>


  .clients-order-table-reassign-stylist {
    .reassign-button {
      padding: 0 15px;
    }
  }

  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 450px;

    .actions {
      justify-content: flex-end;
      margin-left: 30px;
    }
  }

  .stylist {
    display: flex;
    align-items: center;

    .nickname {
      margin-left: 10px;
    }
  }

  /deep/ .multiselect, /deep/ .multiselect__input, /deep/ .multiselect__single {
    font-size: 14px;
  }
</style>
