<template>
  <button class="table-paginator-button"
          type="button"
          :disabled="disabled"
          :title="disabled ? '' : buttonsMap[type].title"
          v-tippy="{ placement: 'bottom', theme: 'light' }"
          @click="$emit('click')">
    <base-icon :icon="buttonsMap[type].icon"
               width="25"
               height="25"
               color="currentColor"/>
  </button>
</template>

<script>
  export default {
    props: {
      type: {
        required: true,
        type: String
      },

      disabled: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        buttonsMap: {
          first: {
            icon: 'arrowFirstPage',
            title: this.$t('shared.tablePaginator.buttonTitle.first')
          },

          previous: {
            icon: 'arrowLeft',
            title: this.$t('shared.tablePaginator.buttonTitle.previous')
          },
          
          next: {
            icon: 'arrowRight',
            title: this.$t('shared.tablePaginator.buttonTitle.next')
          },

          last: {
            icon: 'arrowLastPage',
            title: this.$t('shared.tablePaginator.buttonTitle.last')
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-paginator-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 0;
    color: #797979;
    border: none;
    border-radius: 50%;
    outline: none;
    background: #fff;
    cursor: pointer;
    transition: all .2s;

    &:not(:disabled):hover {
      background: rgba(#000, .08);
    }

    &:not(:disabled):active {
      background: rgba(#000, .15);
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }
</style>