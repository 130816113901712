<template>
  <div class="clients-orders">
    <div class="clients-orders-container">
      <div class="header">
        <base-select-tag class="filter-options"
                         v-model="selectedStatusFilter"
                         :options="$t('orderStatuses')"
                         @update="onStatusChange"/>

        <base-text-box-search class="search"
                              v-if="currentUser.isMainStylist || currentUser.isAdmin"
                              :placeholder="$t('clientsOrders.list.searchPlaceholder')"
                              v-model="filters.search_text"
                              @input="onSearchInput"/>
      </div>

      <div class="content">
        <clients-orders-table :orders="clientsOrders"
                              :filters="filters"
                              :page-size-options="pageSizeOptions"
                              :total-number-of-orders="totalNumberOfOrders"
                              @pageSizeChange="onPageSizeChange"
                              @offsetChange="onOffsetChange"
                              @update="getClientsOrders"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ClientsOrdersTable from './ClientsOrdersTable'
  import ordersService from '@/services/queries/ordersQueries'

  export default {
    components: {
      ClientsOrdersTable
    },

    data() {
      return {
        clientsOrders: [],
        filters: {
          search_text: null,
          status: 'PROCESSING',
          offset: 0,
          limit: 10
        },
        totalNumberOfOrders: 0,
        pageSizeOptions: [10, 20, 30],
        selectedStatusFilter: this.$t('orderStatuses').find(status => status.value === 'PROCESSING')
      }
    },

    metaInfo() {
      return {
        title: this.$t('clientsOrders.list.pageTitle')
      }
    },

    created() {
      this.setInitialFilters()
      this.getClientsOrders()
    },

    methods: {
      setInitialFilters() {
        let isRouteQueryStringEmpty = !Object.keys(this.$route.query).length

        if (isRouteQueryStringEmpty) {
          this.setStatusQuery()
          this.setSearchTextQuery()
          this.setPageLimitQuery()
          this.setPageOffsetQuery()

          return
        }

        this.filters.status = this.$route.query.status
        this.filters.search_text = this.$route.query.search_text
        this.filters.limit = +this.$route.query.limit
        this.filters.offset = +this.$route.query.offset

        if (!this.$route.query.status) {
          return
        }

        this.selectedStatusFilter = this.$t('orderStatuses').find(status => status.value === this.$route.query.status)
      },

      getClientsOrders() {
        ordersService.get(this.filters).then(response => {
          this.clientsOrders = response.orders
          this.totalNumberOfOrders = response.totalCount
        })
      },

      onStatusChange(selectedStatusObject) {
        let status = selectedStatusObject.value === 'ALL' ? null : selectedStatusObject.value

        if (!status) {
          delete this.filters.status
        } else {
          this.filters.status = status
        }

        this.resetOrdersOffset()
        this.getClientsOrders()
        this.setStatusQuery()
      },

      onSearchInput: _.debounce(function(searchText) {
        this.resetOrdersOffset()
        this.getClientsOrders()
        this.setSearchTextQuery(searchText)
      }, 500),

      onOffsetChange(offset) {
        this.filters.offset = offset

        this.getClientsOrders()
        this.setPageOffsetQuery()
      },

      onPageSizeChange(size) {
        this.filters.limit = size

        this.getClientsOrders()
        this.setPageLimitQuery()
      },

      setStatusQuery() {
        let query = { ...this.$route.query }
        let status = this.selectedStatusFilter.value === 'ALL' ? null : this.selectedStatusFilter.value

        if (status) {
          query.status = status
        } else {
          delete query.status
        }

        this.setRouteQuery(query)
      },

      setSearchTextQuery() {
        let query = { ...this.$route.query }
        let searchText = this.filters.search_text

        if (searchText) {
          query.search_text = searchText
        } else {
          delete query.search_text
        }

        this.setRouteQuery(query)
      },

      setPageLimitQuery() {
        this.setRouteQuery({
          ...this.$route.query,
          limit: this.filters.limit
        })
      },

      setPageOffsetQuery() {
        this.setRouteQuery({
          ...this.$route.query,
          offset: this.filters.offset
        })
      },

      setRouteQuery(query) {
        this.$router.replace({ to: 'clientOrders', query })
      },

      resetOrdersOffset() {
        this.filters.offset = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clients-orders {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;

    .clients-orders-container {
      width: 100%;
      max-width: 1300px;
      padding: 30px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      background-color: #F8F8F8;

      .filter-options {
        margin: 0;

        /deep/ .tag {
          margin-bottom: 0;
        }
      }

      .search {
        width: 300px;
      }
    }
  }
</style>
