import profileTransformer from '@/services/transformers/profileTransformer'

export default order => {
  let orderType = order.task_type === 'CLASSIFY_COLOR_TYPE' ? 'colorType' : 'archetype'

  return {
    ...order,
    id: order.task_id,
    startDate: order.date_payment,
    checkDate: order.date_check,
    deadline: order.date_expiration,
    decisionDate: order.date_decision,
    type: orderType,
    stylist: order.stylist ? profileTransformer(order.stylist) : null,
    client: order.client ? profileTransformer(order.client) : null,
    images: order.images,
    clientHeight: order.client_height,
    clientGender: order.client_gender,
    clientBodyShapeId: order.client_figure_type_id,
    stylistConclusionId: orderType === 'colorType' ? order.candidate_color_type_id : order.candidate_archetype_id,
    mainStylistConclusionId: orderType === 'colorType' ? order.color_type_id : order.archetype_id
  }
}