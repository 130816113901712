import orderTransformer from '@/services/transformers/orderTransformer'

const baseURL = 'crm/tasks'

export default {
  /**
   * Get order list.
   *
   * @param params
   * @returns Promise
   */
  get(params = {}) {
    return axios.get(baseURL, {
      params,

      transformResponse(data) {
        return {
          orders: data.items.map(orderTransformer),
          totalCount: data.total_count
        }
      }
    }).then(response => response.data)
  },

  /**
   * Get specific order.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  find(id, params = {}) {
    return axios.get(`${baseURL}/${id}`, {
      params,

      transformResponse(data) {
        return orderTransformer(data)
      }
    }).then(response => response.data)
  },

  /**
   * Set archetype for an order.
   *
   * @param orderId
   * @param archetypeId
   * @returns Promise
   */
  setArchetype(orderId, archetypeId) {
    return axios.patch(`${baseURL}/${orderId}`, {
      action: 'assign_result',
      params: { archetype_id: archetypeId }
    }).then(response => response.data)
  },

  /**
   * Set color type for an order.
   *
   * @param orderId
   * @param colorTypeId
   * @returns Promise
   */
  setColorType(orderId, colorTypeId) {
    return axios.patch(`${baseURL}/${orderId}`, {
      action: 'assign_result',
      params: { color_type_id: colorTypeId }
    }).then(response => response.data)
  },

  /**
   * Approve stylist conclusion (archetype or colorType) in an order. To be used by main stylist.
   *
   * @param orderId
   * @returns Promise
   */
  approveConclusion(orderId) {
    return axios.patch(`${baseURL}/${orderId}`, {
      action: 'confirm_result'
    }).then(response => response.data)
  },

  /**
   * Change stylist in charge for an order.
   *
   * @param orderId
   * @param stylistId
   * @returns Promise
   */
  changeStylist(orderId, stylistId) {
    return axios.patch(`${baseURL}/${orderId}`, {
      action: 'rebind_stylist',
      params: {
        stylist_id: stylistId
      }
    }).then(response => response.data)
  },

  /**
   * Decline photo in an order.
   *
   * @param orderId
   * @param imageId
   *
   * @returns Promise
   */
  declinePhoto(orderId, imageId) {
    return axios.patch(`${baseURL}/${orderId}/images/${imageId}`, {
      decline_reason: 'Плохое качество фотографии'
    }).then(response => response.data)
  },
}
