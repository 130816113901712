<template>
  <div class="clients-orders-table card">
   <table class="table">
      <thead class="table-header">
        <tr class="table-row">
          <th></th>
          <th class="table-header-cell"
              v-for="column in columns"
              :key="column.key">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <router-link class="table-row table-row-hover table-row-click"
                     :to="getOrderRoute(order.id, index)"
                     v-for="(order, index) in orders"
                     :key="order.id"
                     tag="tr">
          <td class="overdue table-cell-centered">
            <base-icon icon="caution"
                       v-if="isOverdue(order)"
                       color="currentColor"/>
          </td>
          <template v-for="column in columns">
            <td class="table-cell table-cell-centered"
                v-if="column.key === 'type'"
                :key="column.key">
              {{ getOrderType(order[column.key]) }}
            </td>

            <td class="table-cell table-cell-centered"
                v-else-if="column.key === 'status'"
                :key="column.key">
              {{ getOrderStatus(order[column.key]) }}
            </td>

            <td class="table-cell table-cell-centered"
                v-else-if="['stylistConclusionId', 'mainStylistConclusionId'].includes(column.key)"
                :key="column.key">
              {{ getConclusionById(order.type, order[column.key]) }}
            </td>

            <td class="table-cell table-cell-centered"
                v-else-if="column.key === 'stylist'"
                :key="column.key">
              <template v-if="order.status !== 'PROCESSING'">
                {{ order.stylist ? order.stylist.nickName : null }}
              </template>

              <clients-orders-table-reassign-stylist v-else-if="order.stylist"
                                                     :order="order"
                                                     :full-stylist-list="stylists"
                                                     @reassign="$emit('update')"/>
            </td>

            <td class="table-cell table-cell-centered"
                v-else-if="['startDate', 'deadline', 'checkDate', 'decisionDate'].includes(column.key)"
                :key="column.key">
              {{ order[column.key] | datetime }}
            </td>

            <td class="table-cell table-cell-centered"
                v-else
                :key="column.key">
              {{ order[column.key] }}
            </td>
          </template>
        </router-link>

        <tr class="table-row no-results"
            v-if="!orders.length">
          <td class="table-cell"
              :colspan="columns.length + 1">
            {{ $t('clientsOrders.list.clientsOrdersTable.noResults') }}
          </td>
        </tr>
      </tbody>

      <tfoot class="table-footer"
             v-if="orders.length">
        <tr class="table-row">
          <td class="table-cell"
              :colspan="columns.length + 1">
            <table-paginator :page-size="pageSize"
                             :page-size-options="pageSizeOptions"
                             :total-number-of-records="totalNumberOfOrders"
                             :offset="offset"
                             :page-size-label="$t('clientsOrders.list.clientsOrdersTable.paginator.pageSizeLabel')"
                             @pageSizeChange="$emit('pageSizeChange', $event)"
                             @offsetChange="$emit('offsetChange', $event)"/>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import TablePaginator from '@/components/Shared/TablePaginator/TablePaginator.vue'
  import ClientsOrdersTableReassignStylist from './ClientsOrdersTableReassignStylist.vue'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    props: {
      orders: {
        required: true,
        type: Array
      },

      filters: {
        required: true,
        type: Object
      },

      pageSizeOptions: {
        required: true,
        type: Array
      },

      totalNumberOfOrders: {
        required: true,
        type: Number
      },
    },

    components: {
      TablePaginator,
      ClientsOrdersTableReassignStylist
    },

    data() {
      return {
        stylists: []
      }
    },

    created() {
      if (this.$currentUser.isMainStylist || this.$currentUser.isAdmin) {
        this.getStylists()
      }
    },

    methods: {
      isOverdue(order) {
        let currentTime =  Math.floor(new Date().getTime() / 1000)

        return order.deadline ? order.deadline <= currentTime : false
      },

      getOrderStatus(status) {
        return this.$t('orderStatuses').find(orderStatus => orderStatus.value === status).label
      },

      getOrderType(type) {
        return this.$t('orderTypes').find(orderType => orderType.value === type).label
      },

      getStylists() {
        let params = {
          user_type: 'stylist'
        }

        profileService.get(params).then(response => {
          this.stylists = response.items
        })
      },

      getOrderRoute(orderId, orderIndexOnPage) {
        return {
          name: 'clientsOrders.item',
          params: { id: orderId },
          query: {
            ...this.$route.query,
            order_offset: +this.$route.query.offset + orderIndexOnPage,
            total_count: this.totalNumberOfOrders
          }
        }
      },

      getConclusionById(orderType, conclusionId) {
        if (!conclusionId) {
          return null
        }

        let getConclusionFunction = orderType == 'colorType' ? this.getColorTypeById : this.getArchetypeById

        return getConclusionFunction(conclusionId).title
      },
    },

    computed: {
      pageSize() {
        return this.filters.limit
      },

      offset() {
        return this.filters.offset
      },

      columns() {
        const columns = [
          {
            key: 'id',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.number')
          },
          {
            key: 'type',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.type')
          },
          {
            key: 'status',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.status')
          },
          {
            key: 'startDate',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.startDate')
          },
          {
            key: 'deadline',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.deadline')
          },
          {
            key: 'decisionDate',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.decisionDate')
          },
          {
            key: 'checkDate',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.checkDate')
          },
          {
            key: 'stylistConclusionId',
            title: this.$currentUser.isStylist && !this.$currentUser.isMainStylist ?
              this.$t('clientsOrders.list.clientsOrdersTable.columns.yourConclusion') :
              this.$t('clientsOrders.list.clientsOrdersTable.columns.stylistConclusion')
          },
          {
            key: 'mainStylistConclusionId',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.mainStylistConclusion')
          },
          {
            key: 'stylist',
            title: this.$t('clientsOrders.list.clientsOrdersTable.columns.stylist')
          }
        ]

        return this.$currentUser.isMainStylist || this.$currentUser.isAdmin ?
          columns : columns.filter(column => column.key !== 'checkDate' && column.key !== 'stylist')
      },

      ...mapGetters('handbooks', [
        'getColorTypeById'
      ]),

      ...mapGetters('handbooks', [
        'getArchetypeById'
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  .clients-orders-table {
    overflow-x: auto;
  }

  .overdue {
    color: #721c24;
  }
</style>
