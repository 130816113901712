<template>
  <div class="table-paginator">
    <div class="table-paginator-page-size">
      <div class="table-paginator-page-size-label">
        {{ pageSizeLabel || $t('shared.tablePaginator.pageSizeLabel') }}
      </div>
      <base-select-tag class="table-paginator-page-size-options"
                       v-model="recordsPerPage"
                       :options="recordsPerPageOptions"
                       @update="onPageSizeChange"/>
    </div>
  
    <div class="table-paginator-records-info">
      {{ $t('shared.tablePaginator.shownRecordsInfo', shownRecordsInfo) }}
    </div>
  
    <div class="table-paginator-buttons">
      <table-paginator-button type="first"
                              :disabled="!offset"
                              @click="onPageChange('first')"/>
      <table-paginator-button type="previous"
                              :disabled="!offset"
                              @click="onPageChange('previous')"/>
      <table-paginator-button type="next"
                              :disabled="offset === lastPageOffset"
                              @click="onPageChange('next')"/>
      <table-paginator-button type="last"
                              :disabled="offset === lastPageOffset"
                              @click="onPageChange('last')"/>
    </div>
  </div>
</template>

<script>
  import TablePaginatorButton from './TablePaginatorButton.vue'

  export default {
    props: {
      pageSize: {
        required: true,
        type: Number
      },
  
      pageSizeOptions: {
        required: true,
        type: Array
      },
  
      totalNumberOfRecords: {
        required: true,
        type: Number
      },
  
      offset: {
        required: true,
        type: Number
      },
  
      pageSizeLabel: {
        required: false,
        type: String,
      }
    },

    components: {
      TablePaginatorButton
    },
  
    data() {
      return {
        recordsPerPage: 0
      }
    },
  
    created() {
      this.recordsPerPage = this.getTransformedPageSizeObject(this.pageSize)
    },
  
    methods: {
      onPageSizeChange(sizeObject) {
        this.$emit('pageSizeChange', sizeObject.value)
      },

      onPageChange(pageType) {
        let offset = this.offset

        let offsetMap = {
          first: 0,
          previous: offset <= this.pageSize ? 0 : offset,
          next: offset + this.pageSize,
          last: this.lastPageOffset
        }

        switch(pageType) {
          case 'first':
            offset = 0
            break
          case 'previous':
            offset = offset <= this.pageSize ? 0 : offset - this.pageSize
            break
          case 'next':
            offset = offset + this.pageSize
            break
          case 'last':
            offset = this.lastPageOffset
        }

        this.$emit('offsetChange', offset)
      },
  
      getTransformedPageSizeObject(pageSize) {
        return { label: pageSize, value: pageSize }
      }
    },
  
    computed: {
        recordsPerPageOptions() {
          return this.pageSizeOptions.map(number => this.getTransformedPageSizeObject(number))
        },
  
        shownRecordsInfo() {
          const lastOrderShown = this.offset + 1 + this.pageSize  > this.totalNumberOfRecords ?
            this.totalNumberOfRecords :
            this.pageSize + this.offset
  
          return {
            lastOrderShown,
            firstOrderShown: this.offset + 1,
            totalNumberOfRecords: this.totalNumberOfRecords
          }
        },

        lastPageOffset() {
          let nextPageOffset = this.offset + this.pageSize

          if (nextPageOffset >= this.totalNumberOfRecords) {
            return this.offset
          }

          let offset = this.offset

          while (nextPageOffset < this.totalNumberOfRecords) {
            offset = nextPageOffset
            nextPageOffset = nextPageOffset + this.pageSize
          }

          return offset
        }
      },
  }
</script>

<style lang="scss" scoped>
  .table-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .table-paginator-page-size,
    .table-paginator-records-info {
      margin-right: 30px;
      font-size: 14px;
      color: #797979;
    }

    .table-paginator-page-size {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .table-paginator-page-size-label {
        margin: 0 10px 5px 0;
      }

      .table-paginator-page-size-options {
        margin: 0;

        /deep/ .tag {
          margin-bottom: 0;
        }
      }
    }

    .table-paginator-buttons {
      display: flex;
      justify-content: space-between;
      width: 200px;
    }
  }
</style>
